/* Customize your main colors in :root variables */
:root {
	--main-background-color: #000;
	--main-text-color: #fff;
	--title-text-color: rgb(255, 26, 179);
}

body {
	background-color: var(--main-background-color);
	font-family: 'Poppins', sans-serif;
	font-size: 1.2em;
}

h2,
p {
	color: var(--main-text-color);
}

h3 {
	font-weight: 700;
	margin-bottom: 0;
}

button {
	font-weight: 700 !important;
	color: var(--main-text-color) !important;
}

.MuiPaper-root {
	padding: 0 !important;
}

.MuiPaper-root h1 {
	margin: 0;
}

.MuiPaper-root br {
	display: none;
}
